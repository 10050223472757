import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  serverUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  supportRequest(userData: any) {
    return this.http.post(`${this.serverUrl}/support-requests`, userData);
  }

}
