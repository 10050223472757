<div class="main-page-background overflow-auto vh-100">
    <app-navbar></app-navbar>
    
    <div class="container help-page page-padding-top-medium overflow-auto">
        <div class="ripple-animation-section page-title-section tt-firs-neue-trial-regular">
            <div class="ripple-position d-none-tab">
                <div>
                    <div class="circles-parent">
                        <div class="solid-circle solid-circle-grey" style="opacity: 0;" id="m-circle-5"></div>
                        <div class="circle3 common-circle common-circle-grey common-circle-1" style="opacity: 0;" id="m-circle-4"></div>
                        <div class="circle2 common-circle common-circle-grey common-circle-1" style="opacity: 0;" id="m-circle-3"></div>
                        <div class="circle1 common-circle common-circle-grey common-circle-1" style="opacity: 0;" id="m-circle-2"></div>
                        <div class="circle0 common-circle-grey" id="m-circle-1" style="opacity: 0;"></div>
                    </div>
                </div>
            </div>
            
            <p class="page-main-title text-center mb-2 mx-md-3">
                <span >
                    <div class="d-flex justify-content-center">
                        <p class="m-0">Help</p> &nbsp;
                    </div>
                </span>
            </p>
        </div>
        
        <div class="page-details-card mx-auto mb-5">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-12 ps-2 mb-3">
                    <div class="card card-help">
                        <div class="card-body help-btn-section">
                            <div class="row w-100 m-auto">
                                
                                <div class="col-lg-12 px-1 col-12">
                                    <button class="btn w-100 btn-primary custom-br">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <p class="mb-0 flex-fill text-gray">Request Support</p>
                                            <svg width="31" height="35" viewBox="0 0 31 35" fill="none" class="d-md-none d-none d-lg-block"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.2858 12.4375C16.9099 12.0103 16.9099 11.3178 17.2858 10.8907C17.6617 10.4635 18.2711 10.4635 18.647 10.8907L23.7803 16.724C24.1562 17.1511 24.1562 17.8437 23.7803 18.2708L18.647 24.1041C18.2711 24.5313 17.6617 24.5313 17.2858 24.1041C16.9099 23.677 16.9099 22.9845 17.2858 22.5573L20.7761 18.5911H8.34141C7.80983 18.5911 7.37891 18.1015 7.37891 17.4974C7.37891 16.8933 7.80983 16.4036 8.34141 16.4036H20.7761L17.2858 12.4375Z"
                                                   />
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-12">
                    <div class="card shadow-card"></div>
                    <div class="card">
                        <div class="card-body help-form-section">
                            <div class="form-section">
                                <h3 class="tt-firs-neue-trial-semibold form-heading mb-3">Request Support</h3>
                                <p class="mb-4 form-subheading sf-pro-regular">Need help with something? Submit the form and will get back to you soon!</p>
                                
                                <form [formGroup]="requestSupportForm" (ngSubmit)="submit()">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6">
                                            <div class="form-group mb-3">
                                                <label for="name" class=" tt-firs-neue-trial-medium mb-2 form-input-label">Full Name</label>
                                                <input type="text" class="form-control sf-pro-regular" [ngClass]="{'err': err || err2 || (submitted && requestSupportForm.controls['name'].errors)}" (keyup)="err = false; err2 = false;" formControlName="name" placeholder="Full Name">
                                                <div>
                                                    <span class="help-block ng-invalid text-danger"
                                                        *ngIf="(!requestSupportForm.controls['name'].valid && !requestSupportForm.controls['name'].pristine) || (requestSupportForm.controls['name'].errors && submitted)">
                                                        <span class="small" *ngIf="submitted && (requestSupportForm.controls['name'].errors?.['required'] || requestSupportForm.controls['name'].errors?.['spacesOnly'])">
                                                            Full Name is required!
                                                        </span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span class="small help-block ng-invalid text-danger" *ngIf="requestSupportForm.controls['name'].hasError('maxlength')">
                                                        Full Name should have max. 64 characters.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-6">
                                            <div class="form-group mb-3">
                                                <label for="email" class=" tt-firs-neue-trial-medium mb-2 form-input-label">Email</label>
                                                <input type="email" class="form-control sf-pro-regular" [ngClass]="{'err': err || err2 || (submitted && requestSupportForm.controls['email'].errors)}" (keyup)="err = false; err2 = false;" formControlName="email" placeholder="abc@company.com" >
                                                <div>
                                                    <span class="help-block ng-invalid text-danger"
                                                        *ngIf="(!requestSupportForm.controls['email'].valid && !requestSupportForm.controls['email'].pristine) || (requestSupportForm.controls['email'].errors && submitted)">
                                                        <span class="small" *ngIf="submitted && requestSupportForm.controls['email'].errors?.['required']">
                                                            Email is required!
                                                        </span>
                                                    </span>
                                                    <span class="help-block ng-invalid text-danger" *ngIf="requestSupportForm.controls['email'].errors && submitted">
                                                        <span class="small" *ngIf="submitted && requestSupportForm.controls['email'].errors?.['email']">
                                                            Enter valid email!
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="form-group mb-3">
                                                <label for="number" class=" tt-firs-neue-trial-medium mb-2 form-input-label">Order No.</label>
                                                <input type="text" class="form-control sf-pro-regular" formControlName="orderId" placeholder="Enter the your order no." readonly="readonly">
                                                <div>
                                                    <span class="help-block ng-invalid text-danger"
                                                        *ngIf="(!requestSupportForm.controls['orderId'].valid && !requestSupportForm.controls['orderId'].pristine) || (requestSupportForm.controls['orderId'].errors && submitted)">
                                                        <span class="small" *ngIf="submitted && requestSupportForm.controls['orderId'].errors?.['required']">
                                                            Order number is required!
                                                        </span>
                                                       
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="form-group mb-5">
                                                <label for="message" class=" tt-firs-neue-trial-medium mb-2 form-input-label">Message</label>
                                                <textarea rows="4" class="form-control textarea-restrict-resize sf-pro-regular" formControlName="message" placeholder="Any additional details regarding the request of support to help you further resolve your issue"></textarea>
                                                <div> 
                                                    <span class="help-block ng-invalid text-danger"
                                                        *ngIf="(!requestSupportForm.controls['message'].valid && !requestSupportForm.controls['message'].pristine) || (requestSupportForm.controls['message'].errors && submitted)">
                                                        <span class="small" *ngIf="submitted && (requestSupportForm.controls['message'].errors?.['required'] || requestSupportForm.controls['message'].errors?.['spacesOnly'])">
                                                            Message is required!
                                                        </span>
                                                    </span>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-6 m-auto mb-4">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <button type="submit" class="btn mb-1 rounded-pill px-4 py-2 w-100" [ngClass]="!requestSupportForm.valid ? 'btn-disabled' : 'btn-primary' " [disabled]="!requestSupportForm.valid || submitted">
                                                    <p class="mb-0 flex-fill sf-pro-regular text-center">
                                                        Request Support &nbsp;
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="submitted"></span>
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>       
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <app-footer></app-footer>
</div>


