
<footer>
    <div class="w-85">
        <div class="row">
            <div class="col justify-content-start">
                <img src="assets/images/auth/gb-logo.png" alt="mdo" class="footer-logo">
                <p class="mt-2 trademark-font">&copy; 2023 GlowingBud LLC</p>
            </div>
            <div class="col d-none d-md-block">
                <div class="row justify-content-end w-95">
                    <div class="col-lg-4 col-md-6 px-lg-1">
                        <img src="assets/images/landing-page/appstore-black.png" class="app-img" alt="">
                    </div>
                    <div class="col-lg-4 col-md-6 px-lg-1">
                        <img src="assets/images/landing-page/playstore-black.png" class="app-img" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            
            <div class="col-6 col-md-3 col-lg-2">
                <ul>
                    <li>Contact Us</li>
                    <li><a href="mailto:hello@glowingbud.com" target="_blank" rel="noopener noreferrer" class="text-decoration-none text-black">hello@glowingbud.com</a> </li>
                    <li>+16466008066</li>
                </ul>
            </div>
            <div class="col-6 col-md-3 col-lg-2">
                <ul>
                    <li>Our Address</li>
                    <li>447 Broadway, 2nd Floor Suite <br class="d-none d-lg-block">#1302, New York - 10013</li>
                </ul>
            </div>
            <div class="col-6 col-md-3 col-lg-2">

                <ul class="social-icons">
                    <li>Follow Us</li>
                    <li class="icon-container ">

                        <a href="https://www.instagram.com/glowing_bud_esim/" target="_blank" rel="noopener noreferrer"
                            class="text-decoration-none text-black">
                            <img class="social-img" src="assets/images/landing-page/instagram-black.webp"
                                alt="instagram-black.webp">
                        </a>

                        <a href="https://www.linkedin.com/company/glowing-bud-llc/?viewAsMember=true" target="_blank"
                            rel="noopener noreferrer" class="text-decoration-none text-black">
                            <img class="social-img" src="assets/images/landing-page/linkedin-logo.webp"
                                alt="linkedin-logo.webp">
                        </a>

                        <a href="https://twitter.com/glowing_bud" target="_blank" rel="noopener noreferrer"
                            class="text-decoration-none text-black">
                            <img class="social-img" src="assets/images/landing-page/twitter-logo.webp"
                                alt="twitter-logo.webp">
                        </a>

                    </li>
                </ul>
                
            </div>
        </div>

        <div class="row">
            <div class="col d-md-none">
                <div class="app-container">
                    <img src="assets/images/landing-page/appstore-black.png" class="app-img" alt="">
                    <img src="assets/images/landing-page/playstore-black.png" class="app-img" alt="">
                </div>
            </div>
        </div>
    </div>
</footer>