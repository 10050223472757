import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import gsap from 'gsap';
import { SupportService } from './service/support.service';
import { AlertService } from '../global/service/alert.service';


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})

export class SupportComponent {
  requestSupportForm!: FormGroup;
  submitted: boolean = false;
  err: boolean = false;
  err2: boolean = false;
  orderId: string | undefined;
  email: string | undefined;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private supportService: SupportService,
    private alertService: AlertService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
      this.email = params['email'];

      this.createForm();
    });

    this.rippleEffectAnimation();
  }

  createForm() {
    this.requestSupportForm = new FormGroup({
      email: new FormControl(this.email, [Validators.email]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
      message: new FormControl(null, [Validators.required]),
      orderId: new FormControl(this.orderId, [Validators.required])
    });
  }

  submit() {
    this.submitted = true;

    if (this.requestSupportForm.invalid) {
      return;
    }

    this.supportService.supportRequest(this.requestSupportForm.value).subscribe(
      (res: any) => {

        if (res) {
          this.requestSupportForm.reset();
          this.submitted = false;
          this.alertService.success(res.message).subscribe((data: any) => { });
          this.router.navigate(['/']);
        }
      }, (err) => {
        this.submitted = false;
        this.alertService.error(err.error.message);
      }
    );
  }

  rippleEffectAnimation() {
    const rippleEffectAnimation = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.5
    });


    rippleEffectAnimation.to('#m-circle-1', {
      opacity: 0.14,
      duration: 0.5
    }).to('#m-circle-2', {
      opacity: 0.14,
      duration: 0.5
    })
      .to('#m-circle-3', {
        opacity: 0.14,
        duration: 0.5
      }).to('#m-circle-4', {
        opacity: 0.14,
        duration: 0.5
      }).to('#m-circle-5', {
        opacity: 0.14,
        duration: 0.5
      })
  }
}


